/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import useWindowSize from '@leshen/ui/src/hooks/useWindowSize'

import {
  Link,
  List,
  ListItem,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'

const Home = () => {
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Clearlink Brand Partners',
          description: '',
          canonical: 'https://clearlink.com/',
          robots: 'nofollow,noindex',
        },
        path: '/partners',
        promoCode: 'SAMPLEPROMO',
        ringPool: 'RINGPOOL123',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Clearlink',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <VariableContent alignMainContent="left">
          <Typography variant="h6">ADT</Typography>
          <Typography variant="h6">Aetna, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.aetnamedicaredirect.com/" target="_blank">
                https://www.aetnamedicaredirect.com/
              </Link>
            </ListItem>
          </List>
          <Typography variant="h6">Altice</Typography>
          <Typography variant="h6">AT&T, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.attsavings.com/" target="_blank">
                https://www.attsavings.com/
              </Link>
            </ListItem>
            <ListItem>
              <Link to="https://www.attexperts.com/" target="_blank">
                https://www.attexperts.com/
              </Link>
            </ListItem>
            <ListItem>AT&T Business</ListItem>
          </List>
          <Typography variant="h6">Berwick (UHC)</Typography>
          <Typography variant="h6">Business Providers, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.business-providers.com/" target="_blank">
                https://www.business-providers.com/
              </Link>
            </ListItem>
          </List>
          <Typography variant="h6">Centene/Wellcare</Typography>
          <Typography variant="h6">
            CenturyLink (now Lumen), including:
          </Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://business.centurylink.com/" target="_blank">
                https://business.centurylink.com/
              </Link>
            </ListItem>
            <ListItem>CenturyLink Business (now Lumen Business)</ListItem>
            <ListItem>
              <Link to="https://www.centurylinkquote.com/" target="_blank">
                https://www.centurylinkquote.com/
              </Link>
            </ListItem>
            <ListItem>
              <Link to="https://www.getcenturylink.com/" target="_blank">
                https://www.getcenturylink.com/
              </Link>
            </ListItem>
            <ListItem>
              <Link to="https://highspeed.centurylink.com/" target="_blank">
                https://highspeed.centurylink.com/
              </Link>
            </ListItem>
            <ListItem>
              <Link to="https://latino.centurylink.com/" target="_blank">
                https://latino.centurylink.com/
              </Link>
            </ListItem>
          </List>
          <Typography variant="h6">Cigna</Typography>
          <Typography variant="h6">Clearlink, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.clearlinkinsurance.com/" target="_blank">
                https://www.clearlinkinsurance.com/
              </Link>
            </ListItem>
          </List>
          <Typography variant="h6">Direct TV, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.directvplans.com/" target="_blank">
                https://www.directvplans.com/
              </Link>
            </ListItem>
            <ListItem>DirectTV Business</ListItem>
          </List>
          <Typography variant="h6">DISH, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.usdish.com/" target="_blank">
                https://www.usdish.com/
              </Link>
            </ListItem>
            <ListItem>
              <Link to="https://www.dish.com/availability" target="_blank">
                https://www.dish.com/availability
              </Link>
            </ListItem>
            <ListItem>
              <Link to="https://www.dishlatino.com/cobertura" target="_blank">
                https://www.dishlatino.com/cobertura
              </Link>
            </ListItem>
          </List>
          <Typography variant="h6">Earthlink</Typography>
          <Typography variant="h6">Frontier Internet, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://go.frontier.com/" target="_blank">
                https://go.frontier.com/
              </Link>
            </ListItem>
            <ListItem>
              <Link to="https://www.frontierbundles.com/" target="_blank">
                https://www.frontierbundles.com/
              </Link>
            </ListItem>
            <ListItem>Frontier Business</ListItem>
            <ListItem>
              <Link
                to="https://www.frontierinternetservice.com/"
                target="_blank"
              >
                https://www.frontierinternetservice.com/
              </Link>
            </ListItem>
            <ListItem>
              <Link to="https://business.frontier.com/" target="_blank">
                https://business.frontier.com/
              </Link>
            </ListItem>
          </List>
          <Typography variant="h6">Humana</Typography>
          <Link to="https://www.movearoo.com/" target="_blank">
            <Typography variant="h6">https://www.movearoo.com/</Typography>
          </Link>
          <Typography variant="h6">Mutual of Omaha</Typography>
          <Typography variant="h6">Quantum Fiber, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.getquantumfiber.com/" target="_blank">
                https://www.getquantumfiber.com/
              </Link>
            </ListItem>
          </List>
          <Typography variant="h6">Regence</Typography>
          <Typography variant="h6">Spectrum</Typography>
          <Typography variant="h6">TBI</Typography>
          <Typography variant="h6">Telarus</Typography>
          <Typography variant="h6">US Direct Business, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.usdirect.com/business" target="_blank">
                https://www.usdirect.com/business
              </Link>
            </ListItem>
          </List>
          <Typography variant="h6">Verizon, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://go.verizon.com/" target="_blank">
                https://go.verizon.com/
              </Link>
            </ListItem>
            <ListItem>
              <Link
                to="https://www.verizonspecials.com/business"
                target="_blank"
              >
                https://www.verizonspecials.com/business
              </Link>
            </ListItem>
            <ListItem>
              <Link to="https://go.verizon.com/residential" target="_blank">
                https://go.verizon.com/residential
              </Link>
            </ListItem>
          </List>
          <Typography variant="h6">Viasat</Typography>
          <Typography variant="h6">Vivint, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.vivintsource.com/" target="_blank">
                https://www.vivintsource.com/
              </Link>
            </ListItem>
          </List>
          <Typography variant="h6">Vivint Solar</Typography>
          <Typography variant="h6">Windstream, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.getwindstream.com/" target="_blank">
                https://www.getwindstream.com/
              </Link>
            </ListItem>
          </List>
          <Link to="https://www.yourlocalsecurity.com/" target="_blank">
            <Typography variant="h6">
              https://www.yourlocalsecurity.com/
            </Typography>
          </Link>
          <Typography variant="h6">Ziply</Typography>
          <Link to="https://www.highspeedinternet.com/" target="_blank">
            <Typography variant="h6">
              https://www.highspeedinternet.com/
            </Typography>
          </Link>
          <Link to="https://www.move.org/" target="_blank">
            <Typography variant="h6">https://www.move.org/</Typography>
          </Link>
          <Typography variant="h6">The Penny Hoarder, including:</Typography>
          <List style={{ paddingLeft: '16px' }}>
            <ListItem>
              <Link to="https://www.thepennyhoarder.com/" target="_blank">
                https://www.thepennyhoarder.com/
              </Link>
            </ListItem>
          </List>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
